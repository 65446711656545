<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-flex fill-height>
      <v-row>
        <v-col cols="12" sm="12" lg="12">
          <BaseCard :height="'100%'" class="memo-inbox">
            <header-left
              slot="left-toolbar"
              @inboxReload="inboxReload"
              v-on:menuAction="menuAction"
              :heading="inboxHeading"
              :enableAction="selectedItems.length > 0"
              :filterModal="filterModal"
              @filterModal="setFilterModal"/>
            <header-right
              @inboxReload="inboxReload"
              slot="right-toolbar"
              v-on:menuAction="menuAction"
              :enableAction="selectedItems.length > 0"/>
            <p
              v-if="
                (!myInboxList || myInboxList.length <= 0) &&
                !axiosLoading &&
                !networkError
              "
              class="align-center center-align"
            >
              {{ $t("text.inbox-empty") }}
            </p>

            <v-list
              :data-intro="$t('introjs.this-is-inbox-list')"
              two-line
              class="pa-1"
              v-if="!networkError && !axiosLoading && myInboxList"
            >
              <template v-for="(item, index) in myInboxList">
                <v-subheader
                  v-if="item.header"
                  :key="item.header"
                  v-text="$t(`${'label.' + item.header}`)"
                >
                </v-subheader>

                <v-divider
                  v-else-if="item.divider"
                  :key="index"
                  :inset="item.inset"
                ></v-divider>

                <v-list-item
                  v-else
                  :key="index"
                  @click.self="showInboxDetail(item.id_hash, item.tipe)"
                  v-ripple="{ class: `primary--text` }"
                >
                  <label
                    class="avoore-checkbox path d-block mr-2"
                    v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                    :for="item.id_hash"
                    :key="item.id_hash"
                    :data-intro="$t('introjs.tick-checkbox-to-show-action')"
                  >
                    <input
                      type="checkbox"
                      class="d-inline-flex"
                      v-model="selectedItems"
                      @change="updateCheckAll"
                      :value="item.id_hash"
                      :id="item.id_hash"
                    />
                    <svg viewBox="0 0 21 21">
                      <path
                        d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                      />
                    </svg>
                  </label>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-avatar
                        v-on="on"
                        v-bind="attrs"
                        class="d-none d-sm-block mr-2"
                        :data-intro="
                          $t('introjs.animated-avatar-meaning-not-readed')
                        "
                        :class="{ blinking: !item.readed_at }"
                        @click="showInboxDetail(item.id_hash, item.tipe)"
                      >
                        <v-img :src="item.avatar_path"></v-img>
                      </v-list-item-avatar>
                    </template>
                    <span>{{ item.avatar_label }}</span>
                  </v-tooltip>

                  <v-list-item-content
                    @click="showInboxDetail(item.id_hash, item.tipe)"
                  >
                    <v-list-item-title>
                      <span
                        :data-intro="$t('introjs.this-is-sender-name')"
                        class="sub-headline"
                        :class="{ 'unread--title': !item.readed_at }"
                        >{{ memoTitle(item) }}</span
                      >
                      <div
                        class="d-inline-flex align-self-center align-center align--vertical-center"
                        :data-intro="$t('introjs.badge-is-tag-for-memo-status')"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="item.tipe === 'eksternal'"
                              href="javascript:void(0)"
                              class="label theme-bg3 text-white f-12"
                              >{{ $t("badge.external-letter") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.external-letter") }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-if="parseInt(item.has_attachments) == 1"
                              class="att-icon mx-1"
                              color="primary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              >{{ iconAttachment }}</v-icon
                            >
                          </template>
                          <span>{{ $t("tooltip.attachment-exist") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="item.tipe === 'xnusantara'"
                              href="javascript:void(0)"
                              class="label theme-bg8 text-white f-12"
                              >{{ $t("badge.xnusantara-letter") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.xnusantara-letter") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="parseInt(item.cc_recipient) === 1"
                              href="javascript:void(0)"
                              class="label theme-bg3 text-white f-12"
                              >{{ $t("badge.cc recipient") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.cc recipient") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-if="item.parent_memo_id_hash"
                              class="att-icon mx-1"
                              color="warning"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              >{{ iconReply }}</v-icon
                            >
                          </template>
                          <span>{{ $t("tooltip.replied-memo") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-if="parseInt(item.secretary_flag) === 1"
                              class="att-icon mx-1"
                              color="warning"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              >{{ iconSecretary }}</v-icon
                            >
                          </template>
                          <span>{{ $t("tooltip.copy for secretary") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="item.is_archived === '1'"
                              href="javascript:void(0)"
                              class="label theme-bg10 text-white f-12"
                              >{{ $t("badge.archive") }}</a
                            >
                          </template>
                          <span>{{
                            $t("tooltip.memo has been archived")
                          }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="item.tipe === 'sppd'"
                              href="javascript:void(0)"
                              class="label theme-bg6 text-white f-12"
                              >{{ $t("badge.sppd") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.sppd form") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="isUrgent(item.tag)"
                              href="javascript:void(0)"
                              class="label theme-bg3 text-white f-12"
                              >{{ $t("badge.urgent") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.urgent") }}</span>
                        </v-tooltip>

                        <div
                          v-if="
                            isSecret(item.tag) && $vuetify.breakpoint.smAndDown
                          "
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="att-icon mx-1"
                                color="warning"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                >{{ iconSecret }}</v-icon
                              >
                            </template>
                            <span>{{ $t("tooltip.secret") }}</span>
                          </v-tooltip>
                        </div>

                        <div v-if="parseInt(item.disposition_revoked) === 1">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="att-icon mx-1"
                                color="warning"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                >{{ iconRevoked }}</v-icon
                              >
                            </template>
                            <span>{{
                              $t(
                                "tooltip.this memo or letters disposition was withdrawn"
                              )
                            }}</span>
                          </v-tooltip>
                        </div>

                        <v-tooltip
                          bottom
                          v-if="
                            isSecret(item.tag) && !$vuetify.breakpoint.smAndDown
                          "
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              href="javascript:void(0)"
                              class="label theme-bg3 text-white f-12"
                              >{{ $t("badge.confidential") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.confidential") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="$vuetify.breakpoint.smAndDown">
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="isUrgent(item.tag)"
                              href="javascript:void(0)"
                              class="label theme-bg3 text-white f-12"
                              >{{ $t("badge.urgent") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.urgent") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="item.tipe === 'bom'"
                              href="javascript:void(0)"
                              class="label theme-bg4 text-white f-12"
                              >{{ $t("badge.directors-memo") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.directors-memo") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="item.tipe === 'subholding'"
                              href="javascript:void(0)"
                              class="label theme-bg7 text-white f-12"
                              >{{ $t("badge.subholding-memo") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.subholding-memo") }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="item.tipe === 'masuk'"
                              href="javascript:void(0)"
                              class="label theme-bg6 text-white f-12"
                              >{{ $t("badge.incoming-letter") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.incoming-letter") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="item.tipe === 'izin_prinsip'"
                              href="javascript:void(0)"
                              class="label theme-bg4 text-white f-12"
                              >{{ $t("badge.permit-memo") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.permit-memo") }}</span>
                        </v-tooltip>
                      </div>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <a
                            v-bind="attrs"
                            v-on="on"
                            v-if="parseInt(item.has_delegation) === 1"
                            href="javascript:void(0)"
                            class="label theme-bg text-white f-12"
                            >{{ $t("badge.has-delegated") }}</a
                          >
                        </template>
                        <span>{{ $t("tooltip.has-delegated") }}</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <a
                            v-bind="attrs"
                            v-on="on"
                            v-if="
                              parseInt(item.has_feedback) === 1 &&
                              parseInt(item.has_delegation) === 1
                            "
                            href="javascript:void(0)"
                            class="label theme-bg2 text-white f-12"
                            >{{ $t("badge.has-feedback") }}</a
                          >
                        </template>
                        <span>{{ $t("tooltip.has-feedback") }}</span>
                      </v-tooltip>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      :data-intro="$t('introjs.this-is-memo-number-and-title')"
                      class="d-none d-sm-block"
                      :class="{ 'unread--title': !item.readed_at }"
                    >
                      {{
                        $t("label.number") + `: ${cutJudul(item.subtitle, 150)}`
                      }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      class="d-block d-sm-none"
                      :class="{ 'unread--title': !item.readed_at }"
                      >{{ cutJudul(item.subtitle, 150) }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                  <v-spacer></v-spacer>

                  <v-tooltip
                    bottom
                    v-if="
                      parseInt(item.has_delegation) === 1 &&
                      !$vuetify.breakpoint.smAndDown
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs" class="processed__icon">
                        <v-icon>{{ iconDelegated }}</v-icon>
                      </div>
                    </template>
                    <span>
                      {{ $t("tooltip.this-memo-has-been-delegated") }}
                    </span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-if="!$vuetify.breakpoint.smAndDown"
                        class="text-right"
                        v-on="on"
                        v-bind="attrs"
                        :data-intro="$t('introjs.this-is-sending-date')"
                        @click="showInboxDetail(item.id_hash, item.tipe)"
                      >
                        {{ $date(item.data_time).format("DD MMM YYYY") }}
                        <br />
                        {{ $date(item.data_time).format("HH:mm") }} WIB
                      </span>
                      <span
                        class="text-right"
                        v-else
                        v-on="on"
                        v-bind="attrs"
                        :data-intro="$t('introjs.this-is-sending-date')"
                        @click="showInboxDetail(item.id_hash, item.tipe)"
                        >{{ $date(item.data_time).format("DD MMM") }}</span
                      >
                    </template>
                    <span>
                      {{
                        `${$t("tooltip.received-at")} ${timeAgo(
                          item.data_time
                        )}`
                      }}
                    </span>
                  </v-tooltip>
                </v-list-item>
              </template>
            </v-list>
            <v-card-text v-if="networkError || axiosLoading || !myInboxList">
              <v-skeleton-loader
                class="mx-auto"
                max-width="100%"
                type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"
              >
              </v-skeleton-loader>
            </v-card-text>
          </BaseCard>
        </v-col>
      </v-row>
    </v-flex>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import headerLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";
import { isIntroActive, rowPerPage } from "@/constants/config";
import dayjs from "dayjs";
import "intro.js/minified/introjs.min.css";

import {
  mdiAttachment,
  mdiStarCircle,
  mdiIncognito,
  mdiBriefcaseCheck,
  mdiClockFast,
  mdiSpeedometerSlow,
  mdiSpeedometerMedium,
  mdiSpeedometer,
  mdiCogTransfer,
  mdiTooltipCheckOutline,
  mdiTransfer,
  mdiReplyCircle,
  mdiFileStarOutline,
  mdiArchiveArrowDownOutline,
  mdiTransferUp,
} from "@mdi/js";

export default {
  components: {
    headerLeft,
    HeaderRight,
  },
  data: () => ({
    iconAttachment: mdiAttachment,
    iconUrgent: mdiClockFast,
    iconBom: mdiStarCircle,
    iconSecret: mdiIncognito,
    iconPermit: mdiBriefcaseCheck,
    iconSlow: mdiSpeedometerSlow,
    iconMedium: mdiSpeedometerMedium,
    iconFast: mdiSpeedometer,
    iconDirect: mdiCogTransfer,
    iconDelegated: mdiTransfer,
    iconRevoked: mdiTransferUp,
    iconFeedback: mdiTooltipCheckOutline,
    iconReply: mdiReplyCircle,
    iconSecretary: mdiFileStarOutline,
    iconArchive: mdiArchiveArrowDownOutline,
    selectedItems: [],
    isCheckedAll: false,
    filterModal: false,
  }),
  filters: {
    diffForHumans: (date) => {
      if (!date) {
        return "";
      }
      return dayjs(date).fromNow();
    },
  },
  computed: {
    ...mapState(["isMobile", "networkError"]),
    ...mapState("inbox", [
      "inboxList",
      "axiosLoading",
      "filterParams",
      "selectAllInbox",
    ]),
    myInboxList() {
      if (!this.inboxList) return [];
      return this.inboxList.data;
    },
    memoIds() {
      if (!this.inboxList || !this.inboxList.data) return [];
      return this.inboxList.data.map(({ id_hash }) => id_hash);
    },
    inboxHeading() {
      if (this.$route.name === "incoming")
        return this.$t("pagetitle.incoming-letter");
      return this.$t("pagetitle.memo-inbox");
    },
    routerName() {
      return this.$route.name;
    },
  },
  created() {
    this.setFilterParams({
      sortBy: "data_time",
      sortByDesc: "desc",
      perPage: rowPerPage,
      currentPage: 1,
      only: "unread,pending,delegated,has_feedback",
      type: "biasa,bom,subholding,xnusantara",
      filter: "",
      from: 0,
      to: 0,
      lastPage: 1,
    });
    if (this.$route.name === "incoming") {
      this.inboxReload("incoming");
    } else {
      this.inboxReload();
    }
  },
  mounted() {
    if (isIntroActive === true) {
      const introJS = require("intro.js");
      setTimeout(() => {
        introJS
          .introJs()
          .setOptions({
            exitOnOverlayClick: false,
          })
          .start();
      }, 2000);
    }
  },
  watch: {
    selectAllInbox(val) {
      if (this.selectedItems == this.memoIds) return;

      this.setSelectAllInbox(false);
      this.selectedItems = this.removeFromArray(
        this.selectedItems,
        this.memoIds
      );

      if (val === true) {
        this.selectedItems = this.addToArray(this.selectedItems, this.memoIds);
      }
      this.updateCheckAll();
    },
    routerName(val) {
      if (val === "incoming") {
        this.inboxReload("incoming");
      } else {
        this.inboxReload();
      }
    },
  },
  methods: {
    ...mapActions("inbox", [
      "loadInbox",
      "emptyInbox",
      "setFilterParams",
      "setAxiosLoading",
      "setSelectAllInbox",
    ]),
    setFilterModal(val) {
      this.filterModal = val;
    },
    memoTitle(param) {
      if (parseInt(param.is_masuk) === 1) return param.lembaga_pengirim_surat;
      return param.title;
    },
    isUrgent(tag) {
      const isUrgentPresent = tag.match(/urgent/i) !== null;
      return isUrgentPresent;
    },
    isSecret(tag) {
      const isSecretPresent = tag.match(/rahasia/i) !== null;
      return isSecretPresent;
    },
    showInboxDetail(id, tipe) {
      //beri jeda untuk animasi ripple
      setTimeout(() => {
        if (tipe === "izin_prinsip") {
          this.$router.push({
            name: "inbox.permit-detail",
            params: { id: id },
          });
        } else {
          this.$router.push({
            name: "inbox.detail",
            params: { id: id },
          });
        }
      }, 300);
    },
    inboxReload() {
      // this.setFilterParams(this.filterParams);
      this.setSelectAllInbox(false);
      //remove dulu selected item
      this.selectedItems = this.removeFromArray(
        this.selectedItems,
        this.memoIds
      );

      this.emptyInbox();
      this.loadInbox(this.filterParams);

      // setTimeout(() => {
      //   this.overlay = false;
      // }, 3000);
    },
    menuAction(params) {
      if (params.command === "filter_data") {
        let myParams = this.filterParams;
        params.slug !== "reset"
          ? (myParams.only = params.slug)
          : (myParams.only = "");
        this.setFilterParams(myParams);
        this.loadInbox(this.filterParams);
      } else if (params.command === "reload") {
        this.loadInbox(this.filterParams);
      }
    },
    updateCheckAll() {
      if (
        this.selectedItems.length == this.memoIds.length &&
        this.selectedItems.length > 0
      ) {
        this.setSelectAllInbox(true);
      } else {
        this.setSelectAllInbox(false);
      }
    },
    cutJudul(str, strLen) {
      if (this._deviceType() == "smartphone") {
        return this.strCutStripped(str, strLen);
      } else {
        return this.strCutStripped(str, strLen);
      }
    },
    cutIsi(str, strLen) {
      if (this._deviceType() == "smartphone") {
        return this.strCutStripped(str, strLen);
      } else {
        return this.strCutStripped(str, strLen);
      }
    },
  },
};
</script>
